@media screen and (min-width: 360px){
    .homeConceptMain{
        background: rgba(250, 247, 252, 1);
        .contentContainer{
            .cards{
                display: flex;
                flex-direction: column;
                margin-top: 25px;
            }
        }
    }
}

@media screen and (min-width: 1220px){
    .homeConceptMain{       
        .contentContainer{
            .cards{
               flex-direction: row;
                margin-top: 50px;
            }
        }
    }
}

