@media screen and (min-width: 360px){
    .homeCategoriesMain{
        .categoriesRepeaterDiv{
            display: flex;
            flex-wrap: wrap;
            min-width: none;
        }
    }
}
@media screen and (min-width: 1220px){
     .homeCategoriesMain{
        padding-bottom: 50px;
    }
}
