@media screen and (min-width: 360px){
    .homeNewsletterMain{
        position: relative;
        .backgroundColor{
            background-color: var(--background-color);
            width: 100%;
            height: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .newsletterContainer{
            background-color: var(--primary-color);
            border-radius: 12px;
            position: relative;
            padding: 10px;
            .title{
                color: white;
                text-align: center;
                width: 100%;
                margin: 0 auto;
                margin-bottom: 30px;
                position: relative;
                z-index: 3;
            }
            .imageShape{
               display: none;  
            }
            .newsletterForm{
                background-color: white;
                display: flex;
                align-items: center;
                width: 87%;
                margin: 0 auto;
                border-radius: 6px;
                padding: 15px 20px 15px 20px;
                margin-bottom: 20px;
                position: relative;
                z-index: 3;
            
            }
        }
    }
}

@media screen and (min-width: 1220px){
    .homeNewsletterMain{
        .newsletterContainer{
            padding: 70px;
            .title{  
                width: 80%;
                margin-bottom: 70px;
            }
            .imageShape{
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;    
                z-index: 1;    
            }
            .newsletterForm{
                width: 60%;
                padding: 15px 20px;
            }
        }
    }
}

