@media screen and (min-width: 360px){
    .homeCommentsMain{
        background-color: var(--background-color);
        .containerInside{
            position: relative;
            display: flex;
            flex-direction: column;
        }
        .titleText{
            width: 70%;
            .title{
                margin-bottom: 15px;
                span{
                    color: var(--primary-color);
                }
            }
        }
        .commentsDiv{
            width: 100%;        
            .oneComment{
                margin-left: 30px;
                margin-top: 30px;
                width: 85% !important;
                .oneCommentContent{
                    background-color: white;
                    border-radius: 7px;
                    padding: 30px;
                    position: relative;   
                    box-shadow: 5px 5px 20px 0px rgba(88, 70, 127, 0.1);
                    height: 300px;
                    .image{
                        position: absolute;
                        left: -25px;
                        top: -25px;
                        border-radius: 50%;
                    }
                    .commentText{
                        p{
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 20px;
                        }
                    }
                    .author{
                        font-size: 13px;
                        line-height: 21px;
                        position: absolute;
                        bottom: 10px;
                    }
                }
            }
        }
        .imageGifts{
            position: absolute;
            right: 0;
            top: 0;
            img{
                width: 50px !important;
                height: 50px !important;
            }
        }
    }

    //Arrow slider
    .nextArrow{
        left: 45px;
        top: -30px;
    }
    .prevArrow{
        left: 0;
        top: -30px;
    }

    .slider_style{
        background-color: transparent;
        height: 420px;
        margin-top: 45px;
    }

}

@media screen and (min-width: 768px){
    .homeCommentsMain{
        .commentsDiv{
            .oneComment{
                margin-top: 80px;
                .oneCommentContent{
                    height: 160px;
                   
                }
            }
        }
        .nextArrow{
            left: 40px;
            top: 0;
        }
        .prevArrow{
            left: 0;
            top: 0;
        }
        .slider_style{
            margin-top: 0;
            height: 350px;
        }
    }
}


@media screen and (min-width: 1220px){
    .homeCommentsMain{
        .containerInside{
            flex-direction: row;
        }
        .titleText{
            width: 35%;
            .title{
                margin-bottom: 30px;
            }
        }
        .commentsDiv{
            width: 55%;        
            .oneComment{
                .oneCommentContent{
                    height: 150px;
                    .commentText{
                        p{
                            font-size: 12px;
                        }
                    }
                    .author{
                        font-size: 14px;
                    }
                }
            }
        }
        .imageGifts{
            img{
                width: 79px !important;
                height: 79px !important;
            }
        }
        .nextArrow{
            left: -57%;
            top: 235px;
        }
        .prevArrow{
            left: -64%;
            top: 235px;
        }
        .slider_style{
            margin-top: 0;
            height: 350px;
        }
    }
}



