@media screen and (min-width: 360px) {

    .oneCardWrapper {
        position: relative;
        border-radius: 6px;
        width: calc(100%);
        height: 0;
        padding-top: 100%;
        margin: 5px;
        overflow: hidden;
    }

    .oneCard {

        .image {
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: 0.5s all;
        }

        .darkBg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(179.8deg, rgba(0, 0, 0, 0) 54.02%, rgba(0, 0, 0, 0.8) 99.82%);
        }

        .titleText {
            position: absolute;
            left: 12px;
            bottom: 10px;
            width: 95%;
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-size: 16px;
                line-height: 20px;
                color: white;
                position: relative;
                font-family: var(--popins);
            }

            .text {
                p {
                    font-size: 12px;
                    line-height: 16px;
                    color: white;
                }
            }
        }

        .link {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .oneCardWrapper {
        width: calc(33.3333% - 10px);
        padding-top: 33.3333%;

    }

    .titleText{
        bottom: 10px;
    }
}

@media screen and (min-width: 992px) {
    .oneCard {
        width: 100%;

        .titleText {
            left: 20px;
            width: 85%;
            height: unset;
            font-family: var(--popins);

            .title {
                font-size: 16px;
                font-family: var(--popins);
            }

            .text {
                p {
                    font-size: 12px;
                }
            }
        }

        &:hover {
            .image {
                transition: 0.25s all;
                transform: scale(1.1);
            }
        }
    }
}
