@media screen and (min-width: 360px){
    .specialOffersMain{
        .productSlider{
            margin-top: 100px;
        }
    //Arrow slider
        .nextArrow{
            right: 39%;
            top: -60px;
        }
        .prevArrow{
            left: 39%;
            top: -60px;
        }
    }
}

@media screen and (min-width: 768px){
    .specialOffersMain{
        .productSlider{
            margin-top: 150px;
        }
        .nextArrow{
            right: 45%;
        }
        .prevArrow{
            left: 45%;
        }
    }
}

@media screen and (min-width: 992px){
    .specialOffersMain{
        .nextArrow{
            right: 46%;
        }
        .prevArrow{
            left: 46%;
        }
    }
}



