
@media screen and (min-width: 360px){
    .homeLatestPostsMain{
        .latestPosts{
            margin-top: 70px;
        }
    }
    .button__bar {
        margin: auto;
        margin-top: 10px;
        padding: 0;
        text-align: center;
        display: flex;
        li {
            list-style: none;
            cursor: pointer;
            display: inline-block;
            margin: 0 3px;
            padding: 0;

            button {
                width: 15px;
                height: 15px;
                color: var(--dots-color);
                background-color: var(--dots-color);
                border: 1px solid var(--dots-color);
                border-radius: 50%;
                font-size: 1px;
                cursor: pointer;   
            }
        }
    }
    .button__bar li.slick-active button {
        background-color: var(--primary-color);
    }
    .button__bar li button:hover,
    .button__bar li button:focus {
        background: var(--primary-color);
        outline: 0;
    }
}

@media screen and (min-width: 1220px){
     .button__bar {
        margin-top: 50px;
    }
}






