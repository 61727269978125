.formStyle{
    display: flex;
    flex-direction: column;
    .inputContainer {
        position: relative;
        margin-bottom: 10px;
        input,
        textarea {
            &:not([type="submit"]) {
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                border: none;
                border-bottom: 1px solid var(--line-color);
                font-family: var(--popins);
                background-color: transparent;
            }
            &:focus {
                outline:none;
                border-bottom: 1px solid var(--primary-color);
                transition: 0.15s;
            }
        }
        input {
            padding: 12px 0;
        }
        textarea {
            padding-top: 12px;
            resize: none;
        }
        button {
            border: 1px solid var(--primary-color);
            margin-top: 30px;
            width: 100% !important; 
        }
        .errorMessage {
            position: absolute;
            left: 0;
            bottom: -15px;
            font-size: 12px;
            color: var(--error-color);
            font-family: var(--popins);
            z-index: 99999;
        }
        &.inputWithImage{
            display: flex;
            align-items: center;
            .inputImage{
                position: absolute;
                right: 5px;
                top:15px;
                cursor: pointer;
            }
            span {
                position: absolute;
                top:45px;
            }
        }
    }
   
    .buttonContainer {
        margin-top: 20px;
        button {
            width: 100%;
        }
    }
}

    .sending, 
    .error-message,
    .error-message-2,
    .success-message{
        font-family: var(--popins);
        margin-top: 15px;
        font-size: 14px;
    }
    .error-message-2, 
    .error-message{
        color: var(--error-color);  
    }
    .success-message{
        color: var(--success-color);
    }

.form-links-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .form-links{
        color: var(--form-links);
        font-family: var(--popins);
        font-size: 12px;
        line-height: 18px;

        &:hover{
            cursor: pointer;
            color: var(--primary-color);
        }
    }
}



input[type=checkbox] {
    position: relative;
    cursor: pointer;
    margin-right: 10px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        top: 0;
        left: 0;
        background-color: white;
        border: 1px solid var(--form-links);
        border-radius: 2px;
    }
    &:checked:before {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 2px;
    }
    &:checked:after {
        content: "";
        display: block;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 5px;
    }
}

.textAfterForm{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    &:after,
    &::before{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--form-line-divider); 
    }
    p{
        margin-right: 10px;
        margin-left: 10px;
        color: var(--form-line-text);
        font-size: 13px;
        line-height: 14px;
    }
} 

.buttons-container{
    .btn-continue{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--form-btn);
        border-radius: 4px;
        position: relative;
        margin-bottom: 10px;
        .icon{
            position: absolute;
            left: 20px;
        }
        p{
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: var(--form-btn);
        }

        &:hover{
            cursor: pointer;
            border: 1px solid var(--primary-color);
            background-color: var(--primary-color);
            p{
                color: white;
            }
        }
    }
}