@media screen and (min-width: 360px){
    .faqMain{
        .faqContainer{

            margin-top: 40px;

            .oneItem{
                background-color: var(--background-color);
                border-radius: 8px;
                padding: 13px 15px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                .question{
                    font-size: 13px;
                    line-height: 20px;
                    width: 90%;
                }
                .answer{
                    p{
                    font-size: 12px;
                    line-height: 17px;
                    }    
                }
            }
        }
    }
    .arrowRight{
        position: absolute;
        top: 25px;
        right: 15px;
                transition: all 0.5s;

    }
    .arrowDown{
        position: absolute;
        top: 25px;
        right: 15px;
        transition: all 0.5s;
        transform: rotate(90deg);
    }
    .show{
        visibility: visible;
        max-height: 100px;
        transition: max-height 0.5s ease-in;
        overflow: auto;
        p{
            font-size: 12px;
            line-height: 17px;
        }   
    }
    .hide{
        visibility: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-out;
    }
}

@media screen and (min-width: 1220px){
    .faqMain{
        .faqContainer{
            .oneItem{
                padding: 20px 40px;
                margin-bottom: 20px;
                .question{
                    font-size: 16px;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }
     .arrowRight{
        top: 30px;
        right: 40px;
    }
      .arrowDown{
        top: 30px;
        right: 40px;
    }
}





