@media screen and (min-width: 360px){
  .oneCard{
    background-color: white;
    border: 1.25px solid rgba(102, 102, 102, 0.08);
    position: relative;
    flex: 1 1 0px;
    padding: 20px;
    z-index: 2;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
     .title {
        font-weight: 700;
      }
      .imgShape {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: -1;
      }
  }
}


@media screen and (min-width: 1220px){
  .oneCard{
    padding: 35px;
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}


