@media screen and (min-width: 360px){
    .titleIcon{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: 0 auto; 
        h2{
            text-align: center;
            margin-bottom: 15px;
            span{
                color: var(--primary-color);
            }
        }
          .icon{
            width: 35px !important;
            height: 35px !important;
        }
       
    }
}

@media screen and (min-width: 1220px){
    .titleIcon{
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        h2{
            margin-bottom: 0;
        }
        .icon{
            margin-bottom: 5px;
            margin-left: 10px;
            width: 53px !important;
            height: 55px !important;
        }
    }
}

