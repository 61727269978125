.form {

  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  .inputWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .inputEmail {
      width: 100%;
      border: none;
      padding: 12px 0;
      border-bottom: 1px solid transparent;
      transition: 0.15s;
      margin-left: 15px;

      &::placeholder {
        color: var(--text-color-light);
      }

      &:focus {
        outline: none;
        border-bottom: 1px solid var(--primary-color);
        transition: 0.15s;
      }
    }
  }

  .submitBtn,
  .submitBtnDisabled {
    margin-left: 15px;
    padding: 11px 22px;
  }

  .submitBtnDisabled {
    opacity: .6;
    pointer-events: none;
  }

  .message {
    position: absolute;
    bottom: -65px;
    background: white;
    width: calc(100% + 20px);
    border-radius: 5px;
    padding: 10px;
    left: -20px;
    text-align: center;

    p {
      margin: 0;
    }
  }
}







