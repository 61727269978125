@media screen and (min-width: 360px){
  .productPreview {
    border: 2px solid rgba(102, 102, 102, 0.08);
    border-radius: 6px;
    background: white;
    margin: 5px;
    transition: .15s;
    &:hover {
      box-shadow: 0px 1.4999148845672607px 29.99659729003906px 1.4999148845672607px rgba(0, 0, 0, 0.05);
      transition: .15s;
    }
    .image {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
    }
    .content {
      padding: 10px;
      text-align: center;
      h3 {
        color: var(--title-color);
        text-align: center;
        font-size: 14px;
        font-family: var(--popins);
        font-weight: 600;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
        transition: .15s;
        height: 43px;
      }
      .priceContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;
        font-weight: 600;
        font-family: var(--popins);
        height: 50px;
        .regularPrice {
          display: inline-block;
          text-align: center;
          color: var(--secundary-color);
          text-decoration: line-through;
          font-size: 13px;
        }
        .price {
          display: inline-block;
          text-align: center;
          border-radius: 5px;
          background: var(--light-background);
          color: var(--primary-color);
          padding: 4px 15px;
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (min-width: 1220px){
  .productPreview {
    .content {
      h3 {
        font-size: 16px;
        &:hover {
          color: var(--primary-color);
          transition: .15s;
        }
      }
      .priceContainer {
        .regularPrice {
          font-size: 14px;
        }
        .price {
          font-size: 14px;
        }
      }
    }
  }
}


