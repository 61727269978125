@media screen and (min-width: 360px){
    .mainHomeBanner{
        .bannerContainer{
            display: flex;
            flex-direction: column;
            padding-bottom: 50px;
            .titleText{
                width: 100%;
                margin-top: 20px;
                h1{
                    width: 100%;
                    .spanPinkTitle{
                        display: inline;
                    }
                }
            }
            .bannerImage{
                display: none;
                position: relative;
                width: 100%;
                height: 330px;
                z-index: 3;
                .bigImage{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 768px){
    .mainHomeBanner{
        .bannerContainer{
            padding-bottom: 0;
            .bannerImage{
                display: block;
                width: 50%;
                height: 330px;
                z-index: 3;
                margin: 0 auto;
                .bigImage{
                    width: 100% !important;
                    height: 100% !important;
                    z-index: 3;
                }
                .imageShape{
                    position: absolute;
                    bottom: 0;
                    left: 15%;
                    z-index: -1;
                    width: 75% !important;
                    height: 110px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 992px){
 .mainHomeBanner{
        .bannerContainer{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .titleText{
                width: 45%;
                margin-top: 0;
                h1{
                    width: 90%;
                    .spanPinkTitle{
                        display: inline;
                    }
                }
            }
            .bannerImage{
                width: 442px;
                height: 457px;
                margin: 0;
                margin-right: 35px;
                .bigImage{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 100% !important;
                    height: 100% !important;
                }
                .imageShape{
                    left: 40px;
                    z-index: 1;
                    width: 374px !important;
                    height: 174px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1220px){
    .mainHomeBanner{
        .bannerContainer{
            .titleText{
                width: 40%;
            }
            .bannerImage{
                margin-right: 105px;
            }
        }
    }
}

