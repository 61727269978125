
@media screen and (min-width: 360px){
    .onePostCardHome{
        // width: 202px;
        margin-right: 10px;
        position: relative;
        overflow: hidden;
        .imageDiv{
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            .image{
                width: 100%;
                height: 100%;
                border-radius: 6px;
                transition: 0.5s all;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .title{
            font-size: 16px;
            line-height: 20px;
            margin-top: 20px;
        }
        .postDate{
            font-size: 12px;
            line-height: 20px;
            color: var(--text-color-light);
        }
        .cardLink{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:hover{
            cursor: pointer;
            overflow: hidden;
            .image{
                transition: 0.5s all;
                transform: scale(1.2);
                
            }
        }
    }
}

@media screen and (min-width: 1220px){
     .onePostCardHome{
        margin-right: 20px;
        &:hover{
            cursor: pointer;
            overflow: hidden;
            .image{
                transition: 0.5s all;
                transform: scale(1.2);
                
            }
        }
    }
}



